<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="610"
    @close="onClose"
    @ok="onSubmit((e) => onSubmitFun(e))"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-input
          title="台位名称"
          :validate="validateInfos.name"
          v-model:value="modelRef.name"
        />

        <e-input-number :labelCol="4" :valueCol="16" title="标准人数" v-model:value="modelRef.peopleCount" />

        <e-input-number :labelCol="4" :valueCol="16" title="顺序" v-model:value="modelRef.sortNum" />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import {} from '@ant-design/icons-vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'
import { TableClass } from '@/apis/table'

const useForm = Form.useForm
const api = new TableClass()
export default defineComponent({
  components: {},
  mixins: [form],
  emits: ['ok'],
  setup () {
    const loading = ref(false)
    const visible = ref(false)
    const isEdit = ref(false)
    const title = ref('')

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()
      delete modelRef.id
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      isEdit,
      loading,
      modelRef,
      validateInfos,
      resetFields,
      validate,
      onClose
    }
  },

  methods: {
    open (formData) {
      if (formData) {
        this.modelRef.name = formData.name
        this.modelRef.peopleCount = formData.peopleCount
        this.modelRef.state = formData.state
        this.modelRef.sortNum = formData.sortNum
        this.modelRef.id = formData.id

        this.title = '编辑：' + formData.name
        this.isEdit = true
      } else {
        this.modelRef = reactive(api.modelRef)

        this.title = '添加台位'
        this.isEdit = false
      }

      this.visible = true
    },

    onSubmitFun (e) {
      const data = toRaw(e)

      if (this.isEdit) {
        api.update(data).then(() => {
          this.loading = false
          this.$message.success('编辑成功')
          this.$emit('ok', data)
          this.onClose()
        })
      } else {
        api.create(data).then(() => {
          this.loading = false
          this.$message.success('添加成功')
          this.$emit('ok', data)
          this.onClose()
        })
      }
    }
  }
})
</script>

<style lang="less" scoped></style>
