<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="6" :xxl="4">
              <e-input
                title="名称"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.Name"
              />
            </a-col>
            <a-col :sm="8" :xxl="5">
              <e-radio
                :labelCol="6"
                :valueCol="16"
                title="状态"
                v-model:value="queryParams.State"
                :options="$store.state.app.table"
              />
            </a-col>
            <a-col>
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
              <a-button
                type="primary"
                style="margin-left: 10px"
                @click="$refs.eForm.open(null)"
              >
                添加台位
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'sortNum'">
              <a-tooltip>
                <template #title>修改顺序</template>
                <a @click.stop="openSortForm(record)">
                  <a-tag color="blue">{{ record.sortNum }}</a-tag>
                </a>
              </a-tooltip>
            </template>

            <template v-if="column.key === 'state'">
              <a-tag :color="['green', 'orange', 'red'][record.state]">{{ $filters.tableLabel(record.state) }}</a-tag>
            </template>

            <template v-if="column.key === 'action'">
              <a-tooltip>
                <template #title>编辑</template>
                <a @click.stop="$refs.eForm.open(record)">
                  <EditOutlined />
                </a>
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>删除</template>
                <a @click.stop="delForm(() => delFormFun(record))">
                  <DeleteOutlined />
                </a>
              </a-tooltip>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>

    <EForm ref="eForm" @ok="getDataList(1)" />
    <ESort ref="eSort" @ok="changeSort" />
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import {
  EditOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'
import { TableClass } from '@/apis/table'
import page from '@/mixins/page'
import EForm from '@/components/forms/table/Form'
import ESort from '@/components/forms/Sort'

const api = new TableClass()
export default defineComponent({
  mixins: [page],
  components: {
    EditOutlined,
    DeleteOutlined,
    EForm,
    ESort
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)
    const id = ref(0)

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '台位名称',
        key: 'name',
        dataIndex: 'name'
      },
      {
        title: '标准人数',
        key: 'peopleCount',
        dataIndex: 'peopleCount',
        width: 100
      },
      {
        title: '顺序',
        key: 'sortNum',
        dataIndex: 'sortNum',
        width: 100
      },
      {
        title: '状态',
        key: 'state',
        dataIndex: 'state',
        width: 100
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 80
      }
    ]
    return {
      columns,
      loading,
      height,
      width,
      id
    }
  },
  created () {
    this.getDataList(1)
  },
  methods: {
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit
      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },
    delFormFun (record) {
      const _this = this
      _this.$confirm({
        title: '确定删除?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          `即将删除数据：${record.name}`
        ),

        onOk () {
          api.del(record.id).then(() => {
            _this.$message.success('删除成功！')
            _this.getDataList(1)
          })
        }
      })
    },

    openSortForm (table) {
      this.id = table.id
      this.$refs.eSort.open(table.sortNum)
    },

    changeSort (e) {
      api.sort(this.id, e).then(() => {
        this.$refs.eSort.handleClose()
        this.$message.success('修改顺序成功')
        this.getDataList(this.page.page)
      })
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    }
  }
})
</script>

<style lang="less" scoped>
.goods-info {
  display: flex;
  .goods-image {
    width: 100px;
    height: 100px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .goods-title {
    padding-left: 10px;
    h1 {
      font-size: 14px;
      padding: 0;
      margin: 0;
      font-weight: 600;
      line-height: 30px;
    }
    p {
      font-size: 12px;
      color: #9b9b9b;
      line-height: 20px;
      height: 40px;
      padding: 0;
      margin: 0;
    }
    .goods-tags {
      font-size: 12px;
      line-height: 30px;
      height: 30px;
    }
  }
}
</style>
